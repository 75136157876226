import React, { createContext, useContext, useReducer } from 'react'
import { QueueActions } from './actions'
import { queueReducer } from './reducer'
import { initialState, State } from './state'

type Dispatch = (action: QueueActions) => void

type QueueContextProps = {
  dispatch: Dispatch
  state: State
}

const QueueContext = createContext<QueueContextProps | undefined>(undefined)

type QueueProviderProps = { children: React.ReactNode }

const QueueProvider = ({ children }: QueueProviderProps) => {
  const [state, dispatch] = useReducer(queueReducer, initialState)

  return <QueueContext.Provider value={{ state, dispatch }}>{children}</QueueContext.Provider>
}

const useQueueContext = () => {
  const context = useContext(QueueContext)

  if (context === undefined) {
    throw new Error('useQueueContext must be used within a QueueProvider')
  }

  return context
}

export { QueueProvider, useQueueContext }
export type { Dispatch }
