import React, { ReactElement } from 'react'
import {
  EuiPage,
  EuiPageContent,
  EuiPageHeader,
  EuiPageSideBar,
  EuiPageBody,
  EuiPageContentBody,
  EuiBottomBar,
} from '@elastic/eui'

interface Props {
  button?: ReactElement
  children?: ReactElement | string
  sideNav?: ReactElement
  bottomBar?: ReactElement
  title?: string
}

export const Page = (props: Props): ReactElement => {
  const { children, sideNav, bottomBar, title } = props
  return (
    <EuiPage paddingSize="none">
      {sideNav && <EuiPageSideBar sticky>{sideNav}</EuiPageSideBar>}
      {/* Double EuiPageBody to accomodate for the bottom bar */}
      <EuiPageBody panelled paddingSize="none">
        <EuiPageBody paddingSize="l">
          <EuiPageHeader bottomBorder restrictWidth iconType="indexRollupApp" pageTitle={title} />
          <EuiPageContent
            hasBorder={false}
            hasShadow={false}
            paddingSize="none"
            color="transparent"
            borderRadius="none"
          >
            <EuiPageContentBody restrictWidth>{children}</EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
        {bottomBar && (
          <EuiBottomBar paddingSize="l" position="sticky">
            {/* Wrapping the contents with EuiPageContentBody allows us to match the restrictWidth to keep the contents aligned */}
            <EuiPageContentBody paddingSize={'none'} restrictWidth>
              {bottomBar}
            </EuiPageContentBody>
          </EuiBottomBar>
        )}
      </EuiPageBody>
    </EuiPage>
  )
}
