import { useEncoderContext } from '../store/EncoderContext'
import { SearchDevicesState, SearchTags } from '../store/state/SelectDevices'
import { useSelectEncoder } from './useSelectEncoder'
import { useDisconnectEncoder } from './useDisconnectEncoder'
import { useEncodeUrl, EncodeUrl } from './useEncodeUrl'
import { useEncodeUrlWithPassword, EncodeUrlWithPassword } from './useEncodeUrlWithPassword'
import { useStop } from './useStop'
import { useReadNFC } from './useReadNFC'

type UseEncoder = {
  devicesLookup: SearchDevicesState
  isDevicePaired: boolean
  isDeviceIdle: boolean
  selectDevice: () => void
  disconnectDevice: () => void
  encodeUrl: (params: EncodeUrl) => Promise<void>
  encodeUrlWithPassword: (params: EncodeUrlWithPassword) => Promise<void>
  stop: () => void
  readNFC: () => Promise<string>
}

const useEncoder = (): UseEncoder => {
  const { state } = useEncoderContext()
  const selectEncoder = useSelectEncoder()
  const disconnectEncoder = useDisconnectEncoder()
  const encodeUrl = useEncodeUrl()
  const encodeUrlWithPassword = useEncodeUrlWithPassword()
  const stop = useStop()
  const readNFC = useReadNFC()

  return {
    devicesLookup: state.devicesLookup,
    isDevicePaired: state.devicesLookup.tag === SearchTags.DeviceSelected,
    isDeviceIdle: state.devicesLookup.tag === SearchTags.DeviceSelected && !state.isEncodingOrScanning,
    selectDevice: selectEncoder,
    disconnectDevice: disconnectEncoder,
    encodeUrl,
    encodeUrlWithPassword,
    stop,
    readNFC,
  }
}

export { useEncoder }
export type { UseEncoder }
