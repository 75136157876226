import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { TappyWrapper } from '../utils'

const useDisconnectEncoder = () => {
  const { dispatch } = useEncoderContext()

  const disconnectEncoder = useCallback(() => {
    TappyWrapper.disconnect()

    dispatch({ tag: ActionTags.NoDevice })

    return
  }, [dispatch])

  return disconnectEncoder
}

export { useDisconnectEncoder }
