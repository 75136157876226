import { SearchDevicesState, nothingYet, searchingDevices, deviceSelected, SearchTags } from './state/SelectDevices'

enum ActionTags {
  SearchingDevices = 'SearchingDevices',
  DoneSearching = 'DoneSearching',
  NoDevice = 'NoDevice',
  EncodingOrScanningStarted = 'EncodingOrScanningStarted',
  Idle = 'Idle',
}

type SearchingDevices = {
  tag: ActionTags.SearchingDevices
}

type DoneSearching = {
  tag: ActionTags.DoneSearching
}

type ResetDevice = {
  tag: ActionTags.NoDevice
}

type EncodingOrScanningSomething = {
  tag: ActionTags.EncodingOrScanningStarted
}

type Idle = {
  tag: ActionTags.Idle
  readValue?: unknown
}

type EncoderActions = SearchingDevices | DoneSearching | ResetDevice | EncodingOrScanningSomething | Idle

type State = {
  devicesLookup: SearchDevicesState,
  isEncodingOrScanning: boolean,
  readValue: unknown | null,
}

const initialState: State = {
  devicesLookup: nothingYet(),
  isEncodingOrScanning: false,
  readValue: null,
}

const encoderReducer = (state: State, action: EncoderActions): State => {
  switch (action.tag) {
    case ActionTags.SearchingDevices: {
      return {
        ...state,
        devicesLookup: searchingDevices(),
      }
    }

    case ActionTags.DoneSearching: {
      return {
        ...state,
        devicesLookup: deviceSelected(),
      }
    }

    case ActionTags.NoDevice: {
      return {
        ...state,
        devicesLookup: nothingYet(),
      }
    }

    case ActionTags.Idle: {
      if (state.devicesLookup.tag !== SearchTags.DeviceSelected) {
        return state
      }

      return {
        ...state,
        isEncodingOrScanning: false,
        readValue: action.readValue,
      }
    }

    case ActionTags.EncodingOrScanningStarted: {
      if (state.devicesLookup.tag !== SearchTags.DeviceSelected) {
        return state
      }

      return {
        ...state,
        isEncodingOrScanning: true
      }
    }

    default:
      return state
  }
}

export { initialState, encoderReducer, ActionTags }
export type { EncoderActions, State }
