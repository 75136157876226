import React, { createContext, useContext, useReducer } from 'react'
import { EncoderActions, encoderReducer, initialState, State } from './EncoderStore'

type Dispatch = (action: EncoderActions) => void

type EncoderContextProps = {
  dispatch: Dispatch
  state: State
}

const EncoderContext = createContext<EncoderContextProps | undefined>(undefined)

type EncoderProviderProps = { children: React.ReactNode }

const EncoderProvider = ({ children }: EncoderProviderProps) => {
  const [state, dispatch] = useReducer(encoderReducer, initialState)

  return <EncoderContext.Provider value={{ state, dispatch }}>{children}</EncoderContext.Provider>
}

const useEncoderContext = () => {
  const context = useContext(EncoderContext)

  if (context === undefined) {
    throw new Error('useEncoderContext must be used within a EncoderProvider')
  }

  return context
}

export { EncoderProvider, useEncoderContext }
