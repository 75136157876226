import React from 'react'
import { useLocation } from 'react-router-dom'
import { EuiHeaderSectionItem, EuiHeader, EuiHeaderLogo, EuiHeaderLink, EuiHeaderLinks } from '@elastic/eui'

import { Link } from './Link'
import { useEncoder } from '../encoder/hooks/useEncoder'

const Header = () => {
  const { pathname } = useLocation()
  const { isDevicePaired, selectDevice, disconnectDevice } = useEncoder()

  const isActive = (url: string) => {
    return url === pathname
  }
  return (
    <EuiHeader>
      <EuiHeaderSectionItem border="right">
        <EuiHeaderLogo iconType="logoElasticsearch">Encoder</EuiHeaderLogo>
      </EuiHeaderSectionItem>

      <EuiHeaderSectionItem>
        <EuiHeaderLinks popoverBreakpoints="none" aria-label="App navigation links example">
          <Link to="/" as={EuiHeaderLink} isActive={isActive('/')}>
            Encode One
          </Link>
          <Link to="/encodeApp" as={EuiHeaderLink} isActive={isActive('/encoreApp')}>
            Encode an app
          </Link>
          <Link to="/examples" as={EuiHeaderLink} isActive={isActive('/examples')}>
            Examples for devs
          </Link>

          {isDevicePaired
            ? <EuiHeaderLink onClick={disconnectDevice} iconType="unlink">Disconnect</EuiHeaderLink>
            : <EuiHeaderLink onClick={selectDevice} iconType="link" >Connect</EuiHeaderLink>}
        </EuiHeaderLinks>
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}

export { Header }
