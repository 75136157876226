enum SearchTags {
  NothingYet = 'NothingYet',
  SearchingDevices = 'SearchingDevices',
  NoDeviceFound = 'NoDeviceFound',
  DeviceSelected = 'DeviceSelected',
}

type NothingYet = {
  tag: SearchTags.NothingYet
}

type SearchingDevices = {
  tag: SearchTags.SearchingDevices
}

type NoDeviceFound = {
  tag: SearchTags.NoDeviceFound
}

type DeviceSelected = {
  tag: SearchTags.DeviceSelected
}

const nothingYet = (): NothingYet => ({ tag: SearchTags.NothingYet })
const searchingDevices = (): SearchingDevices => ({
  tag: SearchTags.SearchingDevices,
})
const noDeviceFound = (): NoDeviceFound => ({ tag: SearchTags.NoDeviceFound })
const deviceSelected = (): DeviceSelected => ({
  tag: SearchTags.DeviceSelected,
})

type SearchDevicesState = NothingYet | SearchingDevices | NoDeviceFound | DeviceSelected

export { nothingYet, searchingDevices, noDeviceFound, deviceSelected, SearchTags }
export type { SearchDevicesState }
