import { GenericCallback, Serial, WebUSBSerialPort } from './WebUSBSerialPort'

type SerialCommunicatorArguments = {
  serial: Serial
  onConnect: GenericCallback | null
  onDisconnect: GenericCallback | null
  onFlush?: GenericCallback | null
  onWrite: GenericCallback | null
}

const noop = (): void => undefined

const toBufferSource = (data: unknown): BufferSource => {
  const result = new Buffer(data as number)
  return result
}

class TappySerialCommunicator {
  private readonly serial: Serial
  private readonly onConnect: GenericCallback
  private readonly onDisconnect: GenericCallback
  private readonly onFlush: GenericCallback
  private readonly onWrite: GenericCallback

  private readRegistered: boolean
  private readChannel: GenericCallback

  private errorRegistered: boolean
  private errorChannel: GenericCallback

  constructor({ serial, onConnect, onDisconnect, onFlush, onWrite }: SerialCommunicatorArguments) {
    this.serial = serial
    this.onConnect = onConnect ?? noop
    this.onDisconnect = onDisconnect ?? noop
    this.onFlush = onFlush ?? noop
    this.onWrite = onWrite ?? noop
    this.readRegistered = false
    this.readChannel = noop
    this.errorRegistered = false
    this.errorChannel = noop
  }

  isConnected = () => {
    return this.serial.isOpen()
  }

  registerReadChannel = () => {
    if (!this.readRegistered) {
      this.readRegistered = true
      this.serial.on('data', this.readChannel)
    }
  }

  registerErrorChannel = () => {
    if (!this.errorRegistered) {
      this.errorRegistered = true
      this.serial.on('error', this.errorChannel)
    }
  }

  connect = () => {
    if (!this.isConnected()) {
      this.serial.open(this.onConnect)
      this.registerReadChannel()
      this.registerErrorChannel()
    }
  }

  disconnect = () => {
    if (this.isConnected()) {
      this.serial.close(this.onDisconnect)
      this.serial.on('data', noop)
      this.serial.on('error', noop)
      this.readRegistered = false
      this.errorRegistered = false
    }
  }

  send = (data: unknown) => {
    if (this.isConnected()) {
      this.serial.write(toBufferSource(data), this.onWrite)
    }
  }

  // This is needed for Tappy lib to work
  flush = () => {
    if (this.isConnected()) {
      this.serial.flush(this.onFlush)
    }
  }

  setDataCallback = (callback: GenericCallback) => {
    this.readChannel = callback
  }

  setErrorCallback = (callback: GenericCallback) => {
    this.errorChannel = callback
  }
}

const create = (onConnect?: GenericCallback): TappySerialCommunicator => {
  return new TappySerialCommunicator({
    serial: new WebUSBSerialPort(),
    onConnect: onConnect ?? (() => console.log('serial communicator connected')),
    onDisconnect: () => console.log('serial communicator disconnected'),
    onWrite: () => console.log('serial communicator ready to write'),
  })
}

export const SerialCommunicator = {
  create,
}
