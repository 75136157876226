import { EuiFlexGroup, EuiBadge, EuiFlexItem, EuiFormRow, EuiFieldText, EuiButton, EuiCallOut, EuiFlexGrid, EuiTitle, EuiPanel, EuiFieldPassword } from '@elastic/eui'
import React, { useState } from 'react'
import { Page } from '../common'
import { useEncoder } from '../encoder/hooks/useEncoder'

const MSJ_DEFAULT_PASSWORD = 'OurUn1queIPisAWES0M3';

const Examples = (): React.ReactElement => {
  const [values, setValues] = useState({
    url: '',
    password: MSJ_DEFAULT_PASSWORD,
  })
  const [readValue, setReadValue] = useState<string | null>(null)

  const { isDevicePaired, isDeviceIdle, encodeUrl, stop, readNFC, selectDevice, disconnectDevice, encodeUrlWithPassword } = useEncoder()

  const onChange = (key: string, targetValue: string) => (e: any) => {
    setValues({ ...values, [key]: e.target[targetValue] })
  }

  const encoreUrlWithoutPass = (lock = false) => {
    encodeUrl({ url: values.url, lock })
  }
  const encoreUrlWithPassword = () => {
    encodeUrlWithPassword({ password: values.password, url: values.url })
  }

  const onRead = async () => {
    const value = await readNFC()
    setReadValue(value)
  }

  return (
    <Page title="Examples pour DEVS">
      <EuiFlexGrid columns={2}>
        <EuiFlexItem>
          <EuiPanel >

            <EuiTitle>
              <h2>Connect or disconned the device</h2>
            </EuiTitle>
            <EuiFlexGroup wrap responsive={false} gutterSize="xs">
              <EuiFlexItem grow={false}>
                <p>Device status: </p>
              </EuiFlexItem>

              {!isDevicePaired &&
                <EuiFlexItem grow={false}>
                  <EuiBadge color="warning">Disconnected</EuiBadge>
                </EuiFlexItem>
              }
              {isDevicePaired &&
                <EuiFlexItem grow={false}>
                  <EuiBadge color="secondary">Connected</EuiBadge>
                </EuiFlexItem>
              }
              {isDeviceIdle &&
                <EuiFlexItem grow={false}>
                  <EuiBadge color="primary">Idle</EuiBadge>
                </EuiFlexItem>
              }
              {!isDeviceIdle &&
                <EuiFlexItem grow={false}>
                  <EuiBadge color="danger">Busy</EuiBadge>
                </EuiFlexItem>
              }
            </EuiFlexGroup>
            <br />
            {!isDevicePaired &&
              <EuiButton iconType="link" onClick={selectDevice}>Connect device
              </EuiButton>}
            {isDevicePaired && isDeviceIdle &&
              <EuiButton iconType="unlink" onClick={disconnectDevice}>Disconnect device
              </EuiButton>}
            {isDevicePaired && !isDeviceIdle &&
              <EuiButton iconType="crossInACircleFilled" color="danger" onClick={stop}>Stop device
              </EuiButton>}
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel >

            <EuiTitle>
              <h2>Read the value of a NFC</h2>
            </EuiTitle>

            {!isDevicePaired &&
              <EuiCallOut color="warning" size="s" iconType="wrench" title="You must connect the device before using it" />
            }
            {isDevicePaired && isDeviceIdle &&
              <EuiButton disabled={!isDeviceIdle} onClick={onRead}>Start reading
              </EuiButton>}
            {isDevicePaired && !isDeviceIdle &&
              <EuiButton color="danger" iconType="crossInACircleFilled" onClick={stop}>Cancel read
              </EuiButton>}

            {readValue !== null && <p>{readValue}</p>}
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel>

            <EuiTitle>
              <h2>Encode a URL</h2>
            </EuiTitle>
            <EuiFormRow label="Url to encode" helpText="Ex: https://redirect.msj.world/nfc/vero/1 ou https://domaine.personnalisé/nfc/1">
              <EuiFieldText disabled={!isDeviceIdle} value={values.url} id="url" onChange={onChange('url', 'value')} />
            </EuiFormRow>
            {!isDevicePaired &&
              <EuiCallOut color="warning" size="s" iconType="wrench" title="You must connect the device before using it" />
            }

            {isDevicePaired && isDeviceIdle && <EuiButton disabled={!isDeviceIdle} onClick={() => encoreUrlWithoutPass()}>Encode</EuiButton>}
            <br />
            <br />
            {isDevicePaired && isDeviceIdle && <EuiButton disabled={!isDeviceIdle} onClick={() => encoreUrlWithoutPass(true)}>Encode Locked</EuiButton>}
            {isDevicePaired && !isDeviceIdle && <EuiButton color="danger" iconType="crossInACircleFilled" onClick={stop}>Cancel encoding</EuiButton>}

          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>

            <EuiTitle>
              <h2>Encode a URL With a passowrd</h2>
            </EuiTitle>
            <EuiFormRow label="Url to encode" helpText="Ex: https://redirect.msj.world/nfc/vero/1 ou https://domaine.personnalisé/nfc/1">
              <EuiFieldText disabled={!isDeviceIdle} value={values.url} id="url" onChange={onChange('url', 'value')} />
            </EuiFormRow>
            <EuiFormRow label="Set optional password (Must be at least 4 characters)" helpText="Ex: I<3Ch33s3">
              <EuiFieldPassword disabled={!isDeviceIdle} value={values.password} id="password" onChange={onChange('password', 'value')} />
            </EuiFormRow>

            {!isDevicePaired &&
              <EuiCallOut color="warning" size="s" iconType="wrench" title="You must connect the device before using it" />
            }
            {isDevicePaired && isDeviceIdle &&
              <EuiButton disabled={!isDeviceIdle} onClick={encoreUrlWithPassword}>Encode with password</EuiButton>
            }
            {isDevicePaired && !isDeviceIdle &&
              <EuiButton color="danger" onClick={stop}>Cancel encoding</EuiButton>
            }

          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGrid>

    </Page>
  )
}

export { Examples }
