
import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { ScanNFCResult, TappyWrapper } from '../utils'

export type EncodeUrl = {
  url: string,
  lock: boolean | undefined,
}

const useEncodeUrl = () => {
  const { dispatch } = useEncoderContext()

  const encodeUrl = useCallback(({ lock, url }: EncodeUrl): Promise<void> => {
    return new Promise((resolve, reject) => {

      dispatch({ tag: ActionTags.EncodingOrScanningStarted })

      TappyWrapper.encodeUrl(url, lock, (result: ScanNFCResult) => {
        dispatch({ tag: ActionTags.Idle });

        if (result.status === 'error') {
          reject(result.data)
          return
        }

        resolve()
      })

    })


  }, [dispatch])

  return encodeUrl
}

export { useEncodeUrl }
