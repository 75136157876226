import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldText, EuiButton, EuiFormControlLayoutDelimited } from '@elastic/eui'
import React, { useState, useMemo } from 'react'
import { Page, EncodingProcess } from '../common'
import { useEncoder } from '../encoder/hooks/useEncoder'
import Scroll from 'react-scroll'

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const wait = (time: number) => new Promise((res) => {
  setTimeout(res, time)
})

export type color = "primary" | "success" | "warning" | "danger" | undefined
type EncodingProcessItem = {
  url: string
  status: string
  color: color
}


const MESSAGES = {
  toEncode: 'To encode',
  initial: 'Waiting for NFC',
  displayValue: (tokenValue: string) => `NFC Initial value: ${tokenValue}; Encoding...`,
  displaySameValue: (tokenValue: string) => `Waiting for a new NFC because this one have ${tokenValue} as value`
}



const EncodeApp = (): React.ReactElement => {

  // const myQueu = useQueue()
  // console.log(myQueu)

  const [values, setValues] = useState({
    baseUrl: 'https://',
    rangeFrom: 1,
    rageTo: 10,
  })

  const [currentEncoding, setCurrentEncoding] = useState('')

  const [encodingProcess, setEncodinProcess]: [EncodingProcessItem[], any] = useState([{ url: '', status: '', color: 'primary' }])
  const [latestEncodedUrl, setLatestEncodedUr] = useState('')
  const getLatestEncodedUrl = useMemo(() => { return latestEncodedUrl }, [latestEncodedUrl])

  const { isDevicePaired, isDeviceIdle, encodeUrlWithPassword, stop, readNFC } = useEncoder()

  const onChange = (key: string, targetValue: string) => (e: any) => {
    setValues({ ...values, [key]: e.target[targetValue] })
  }

  const setEncodingProgress = (url: string, newStatus: string, color: color) => {
    setEncodinProcess((initialUrls: EncodingProcessItem[]) => {
      return initialUrls.map(a => (
        a.url === url ? { ...a, status: newStatus, color } : a
      ))
    })
  }

  const generateUrls = () => {

    const urlsToEncode = []


    for (let index = (values.rangeFrom || 0); index <= values.rageTo; index++) {
      urlsToEncode.push({
        url: `${values.baseUrl}/${index}`,
        status: MESSAGES.toEncode
      })
    }
    setLatestEncodedUr('')
    setCurrentEncoding('')
    setEncodinProcess(urlsToEncode)

  }

  const encodingAToken = (url: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      setCurrentEncoding(url)

      setEncodingProgress(url, MESSAGES.initial, 'warning')
      /*
              let tokenValue = await readNFC()
              setEncodingProgress(url, MESSAGES.displayValue(tokenValue))

              console.log({tokenValue, latestEncodedUrl: getLatestEncodedUrl})

                while (tokenValue === getLatestEncodedUrl) {
                  setEncodingProgress(url, MESSAGES.displaySameValue(tokenValue))
                  tokenValue = await readNFC()
                }
      */

      await encodeUrlWithPassword({ url, password: 'OurUn1queIPisAWES0M3' })

      //  setLatestEncodedUr(url)

      setEncodingProgress(url, `Encoded`, 'success')
      await wait(1500)
      scroller.scrollTo(`url-${url}`, {
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId: 'scrollContainer',
        offset: 50, // Scrolls to element + 50 pixels down the page
      })

      resolve()

    })



  }

  const testingAToken = (expectedUrl: string): Promise<void> => {

    return new Promise(async (resolve, reject) => {
      setCurrentEncoding(expectedUrl)

      setEncodingProgress(expectedUrl, MESSAGES.initial, 'warning')

      const readValue = await readNFC()

      if (readValue === expectedUrl) {
        setEncodingProgress(expectedUrl, `Read and Validated`, 'success')
      } else {
        setEncodingProgress(expectedUrl, `WRONG ${readValue}`, 'danger')
      }

      scroller.scrollTo(`url-${expectedUrl}`, {
        duration: 150,
        delay: 0,
        smooth: true,
        containerId: 'scrollContainer',
        offset: 50, // Scrolls to element + 50 pixels down the page
      })
      await wait(1500)
      resolve()
    })
  }

  async function batchEncode() {

    setLatestEncodedUr('')
    setCurrentEncoding('')
    scroller.scrollTo(`url-${encodingProcess[0].url}`, {
      duration: 150,
      delay: 0,
      smooth: true,
      containerId: 'scrollContainer',
      offset: 50, // Scrolls to element + 50 pixels down the page
    })

    for (const urlToEncode of encodingProcess) {
      await encodingAToken(urlToEncode.url);
    }
  }
  async function batchRead() {

    setLatestEncodedUr('')
    setCurrentEncoding('')
    scroller.scrollTo(`url-${encodingProcess[0].url}`, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'scrollContainer',
      offset: 50, // Scrolls to element + 50 pixels down the page
    })

    for (const urlToEncode of encodingProcess) {
      await testingAToken(urlToEncode.url);
    }
  }



  return (
    <Page title="Encoder un jeton">
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow label="Base of the Url" helpText="Ex: https://redirect.msj.world/nfc/vero/1 ou https://domaine.personnalisé/nfc/1">
            <EuiFieldText value={values.baseUrl} id="url" onChange={onChange('baseUrl', 'value')} />
          </EuiFormRow>
          <EuiFormRow label="Range of nfc to encode" helpText="Ex: 1 to 20">
            <EuiFormControlLayoutDelimited
              startControl={
                <input
                  type="number"
                  placeholder="Frist token"
                  className="euiFieldNumber"
                  min="1"
                  value={values.rangeFrom}
                  onChange={onChange('rangeFrom', 'value')}
                />
              }
              endControl={
                <input
                  type="number"
                  min="1"
                  placeholder="Last"
                  className="euiFieldNumber"
                  value={values.rageTo}
                  onChange={onChange('rageTo', 'value')}
                />
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow hasEmptyLabelSpace>
            <>
              <EuiButton onClick={generateUrls}>Generate Urls</EuiButton>
              <EuiButton disabled={!isDevicePaired || !isDeviceIdle || encodingProcess.length === 0} onClick={batchEncode} fill>Encode</EuiButton>
              <EuiButton disabled={!isDevicePaired || !isDeviceIdle || encodingProcess.length === 0} onClick={batchRead} fill>Test</EuiButton>
              {isDevicePaired && !isDeviceIdle && <EuiButton onClick={stop}>Stop</EuiButton>}
            </>
          </EuiFormRow>
          <EuiFormRow hasEmptyLabelSpace>
            <>Latest encoded:
            {latestEncodedUrl}</>
          </EuiFormRow>
          <EuiFormRow id="scrollContainer">
            <div style={{ height: '500px', overflow: 'scroll' }}>
              {encodingProcess.map(({ url, status, color }) => url &&
                <Element name={`url-${url}`}>
                  <EncodingProcess
                    url={url}
                    encodingStatus={status}
                    color={color}
                    key={url}
                    onClick={() => {/* encodingAToken(url) */ }}
                  />
                </Element>
              )}
            </div>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  )
}

export { EncodeApp }
