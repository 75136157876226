import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { TappyWrapper } from '../utils'

const useStop = () => {
  const { dispatch } = useEncoderContext()

  const stop = useCallback(() => {
    TappyWrapper.stop()

    dispatch({ tag: ActionTags.Idle })
  }, [dispatch])

  return stop
}

export { useStop }
