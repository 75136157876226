import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { ScanNFCResult, TappyWrapper } from '../utils'

const useReadNFC = () => {
  const { dispatch } = useEncoderContext()

  const readNFC = useCallback((): Promise<string> => {
    return new Promise((resolve, reject) => {

      dispatch({ tag: ActionTags.EncodingOrScanningStarted })

      TappyWrapper.read((result: ScanNFCResult) => {
        dispatch({ tag: ActionTags.Idle });

        if (result.status === 'error') {
          reject(result.data)
          return
        }

        resolve(result.data)
      })

    })
  }, [dispatch])

  return readNFC
}

export { useReadNFC }
