import { Stream } from 'stream'

type GenericCallback = (something: unknown) => void

type Serial = {
  close: (callback: GenericCallback) => Promise<void>
  drain: GenericCallback
  flush: GenericCallback
  isOpen: () => boolean
  open: (callback: GenericCallback) => Promise<void>
  write: (data: BufferSource, callback: GenericCallback) => Promise<void>
  on: (channel: string, callback: GenericCallback) => void
}

type UsbSearchVersion = {
  vendorId: number
  productId: number
}

const FDTI_VERSION: UsbSearchVersion = { vendorId: 0x0403, productId: 0x6001 }
const NATIVE_VERSION: UsbSearchVersion = { vendorId: 0x04d8, productId: 0x0053 }
const CDC_VERSION: UsbSearchVersion = { vendorId: 0x03d8, productId: 0x000a }

const DEFAULT_FILTERS = [
  FDTI_VERSION, // TapTrack Tappy FTDI 232R USB
  NATIVE_VERSION, // TapTrack Tappy Native USB
  CDC_VERSION, // TapTrack Tappy New FTDI USB
]

type ProductUsbVersion = {
  id: 'FTDI' | 'native' | 'CDC'
  hasCommandsDuringSetup: boolean
  dataChannelInterfaceId: number
  readChannelId: number
  writeChannelId: number
}

const findVersion = (version: UsbSearchVersion): ProductUsbVersion => {
  if (version.vendorId === NATIVE_VERSION.vendorId && version.productId === NATIVE_VERSION.productId) {
    return {
      id: 'native',
      hasCommandsDuringSetup: false,
      dataChannelInterfaceId: 0,
      readChannelId: 1,
      writeChannelId: 1,
    }
  }

  if (version.vendorId === CDC_VERSION.vendorId && version.productId === CDC_VERSION.productId) {
    return {
      id: 'CDC',
      hasCommandsDuringSetup: false,
      dataChannelInterfaceId: 1,
      readChannelId: 2,
      writeChannelId: 2,
    }
  }

  return {
    id: 'FTDI',
    hasCommandsDuringSetup: true,
    dataChannelInterfaceId: 0,
    readChannelId: 1,
    writeChannelId: 2,
  }
}

const FTDI_SIO_RESET = 0x00
const FTDI_SIO_SET_DATA = 0x04
const FTDI_SET_DATA_DEFAULT = 0x0008
const FTDI_SIO_MODEM_CTRL = 0x01
const FTDI_SET_MODEM_CTRL_DEFAULT1 = 0x0101
const FTDI_SET_MODEM_CTRL_DEFAULT2 = 0x0202
const FTDI_SET_MODEM_CTRL_DEFAULT3 = 0x0100
const FTDI_SET_MODEM_CTRL_DEFAULT4 = 0x0200
const FTDI_SIO_SET_FLOW_CTRL = 0x02
const FTDI_SET_FLOW_CTRL_DEFAULT = 0x0000
const FTDI_SIO_SET_BAUD_RATE = 0x03
const FTDI_BAUDRATE_9600 = 0x4138
const FTDI_BAUDRATE_115200 = 0x001a

type NoDevice = {
  tag: 'NoDevice'
}

type DeviceSelected = {
  tag: 'DeviceSelected'
  device: USBDevice
  version: ProductUsbVersion
}

type DeviceReady = {
  tag: 'DeviceReady'
  device: USBDevice
  version: ProductUsbVersion
}

type WebUSBSerialPortState = NoDevice | DeviceSelected | DeviceReady

const noDevice = (): NoDevice => ({ tag: 'NoDevice' })
const deviceSelected = (device: USBDevice): DeviceSelected => ({
  device,
  version: findVersion({ vendorId: device.vendorId, productId: device.productId }),
  tag: 'DeviceSelected',
})
const deviceReady = (device: USBDevice): DeviceReady => ({
  device,
  version: findVersion({ vendorId: device.vendorId, productId: device.productId }),
  tag: 'DeviceReady',
})

class WebUSBSerialPort extends Stream implements Serial {
  private state: WebUSBSerialPortState

  constructor() {
    super()
    this.state = noDevice()
  }

  isOpen = (): boolean => {
    return this.state.tag === 'DeviceReady'
  }

  close = async (callback: GenericCallback) => {
    if (this.state.tag === 'DeviceReady') {
      if (this.state.version.hasCommandsDuringSetup) {
        await this.setControlCommand(FTDI_SIO_MODEM_CTRL, FTDI_SET_MODEM_CTRL_DEFAULT3)
        await this.setControlCommand(FTDI_SIO_MODEM_CTRL, FTDI_SET_MODEM_CTRL_DEFAULT4)
      }
      await this.state.device.releaseInterface(this.state.version.dataChannelInterfaceId)
      await this.state.device.close()

      this.state = noDevice()
      callback('device closed')
      return
    }

    this.emit('error', new Error('Cannot close an unready device'))
  }

  private setControlCommand = (request: number, value: number, index: number = 0) => {
    return this.state.tag !== 'NoDevice'
      ? this.state.device.controlTransferOut({
          requestType: 'vendor',
          recipient: 'device',
          request: request,
          value: value,
          index: index,
        })
      : Promise.resolve()
  }

  open = async (callback: GenericCallback): Promise<void> => {
    if (this.state.tag === 'DeviceReady') {
      callback('serial port already open')
      this.emit('error', new Error('Serial port already open'))
      throw new Error('Serial port already open')
    }

    if (this.state.tag === 'NoDevice') {
      const selectedDevice = await navigator.usb.requestDevice({
        filters: DEFAULT_FILTERS,
      })

      this.state = deviceSelected(selectedDevice)

      await this.state.device.open()
      await this.state.device.selectConfiguration(1)
      await this.state.device.claimInterface(this.state.version.dataChannelInterfaceId)

      if (this.state.version.hasCommandsDuringSetup) {
        await this.setControlCommand(FTDI_SIO_RESET, 0x00)
        await this.setControlCommand(FTDI_SIO_SET_DATA, FTDI_SET_DATA_DEFAULT)
        await this.setControlCommand(FTDI_SIO_MODEM_CTRL, FTDI_SET_MODEM_CTRL_DEFAULT1)
        await this.setControlCommand(FTDI_SIO_MODEM_CTRL, FTDI_SET_MODEM_CTRL_DEFAULT2)
        await this.setControlCommand(FTDI_SIO_SET_FLOW_CTRL, FTDI_SET_FLOW_CTRL_DEFAULT)
        await this.setControlCommand(FTDI_SIO_SET_BAUD_RATE, FTDI_BAUDRATE_9600)
        await this.setControlCommand(FTDI_SIO_SET_BAUD_RATE, FTDI_BAUDRATE_115200)

        let currentSioSetData = 0x0000
        currentSioSetData &= ~1
        currentSioSetData &= ~(1 << 1)
        currentSioSetData &= ~(1 << 2)
        currentSioSetData |= 1 << 3
        await this.setControlCommand(FTDI_SIO_SET_DATA, currentSioSetData)

        currentSioSetData &= ~(1 << 8)
        currentSioSetData &= ~(1 << 9)
        currentSioSetData &= ~(1 << 10)
        await this.setControlCommand(FTDI_SIO_SET_DATA, currentSioSetData)

        currentSioSetData &= ~(1 << 11)
        currentSioSetData &= ~(1 << 12)
        currentSioSetData &= ~(1 << 13)
        await this.setControlCommand(FTDI_SIO_SET_DATA, currentSioSetData)
      }

      this.state = deviceReady(this.state.device)
      callback('device ready')
      this.listeningForData()
    }
  }

  private listeningForData = async () => {
    if (this.state.tag === 'DeviceReady') {
      try {
        const result = await this.state.device.transferIn(this.state.version.readChannelId, 64)

        if (result.status === 'ok') {
          const arr = new Uint8Array(result.data?.buffer ?? [])

          switch (this.state.version.id) {
            case 'FTDI':
              if (arr.length > 2 && arr[0] === 0x01 && arr[1] === 0x60) {
                this.emit('data', arr.slice(2))
              }
              break
            case 'native':
            case 'CDC':
              if (arr.length > 0) {
                this.emit('data', arr)
              }
              break
          }
        }

        if (result.status === 'stall') {
          console.warn('Endpoint stalled. Clearing.')
          await this.state.device.clearHalt('in', this.state.version.readChannelId)
        }
      } catch {}

      setTimeout(this.listeningForData, 50)
    }
  }

  write = async (data: BufferSource, callback: GenericCallback) => {
    if (this.state.tag === 'DeviceReady') {
      const result = await this.state.device.transferOut(this.state.version.writeChannelId, data)

      if (result.status !== 'ok') {
        callback(`Could not write: ${result.status}`)
        this.emit('error', new Error(`Could not write: ${result.status}`))
        throw new Error(`Could not write: ${result.status}`)
      }

      callback(result)
    }
  }

  flush = () => {}

  drain = () => {}
}

export { WebUSBSerialPort }
export type { GenericCallback, Serial }
