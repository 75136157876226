import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Header } from './common'

import '@elastic/eui/dist/eui_theme_light.css'
import { Encoder } from './pages/Encoder'
import { EncodeApp } from './pages/EncodeApp'
import { Examples } from './pages/Examples'
import { EncoderProvider } from './encoder/store/EncoderContext'
import { QueueProvider } from './encodingQueue/store/QueueContext'
import React from 'react'

function App() {
  return (
    <BrowserRouter>
      <EncoderProvider>
        <QueueProvider>
          <Header />
          <Switch>
            <Route path="/examples" component={Examples} />
            <Route path="/encodeApp" component={EncodeApp} />
            <Route path="/" component={Encoder} />
          </Switch>
        </QueueProvider>
      </EncoderProvider>
    </BrowserRouter>
  )
}

export default App
