import { ActionTags, QueueActions } from "./actions"
import { State } from "./state"


const queueReducer = (state: State, action: QueueActions): State => {
  switch (action.tag) {

    case ActionTags.SetList: {
      return {
        ...state,
        list: action.list,
        currentPosition: 0,
      }
    }

    default:
      return state
  }
}

export { queueReducer }
