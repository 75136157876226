import Tappy from '@taptrack/tappy'
import TappyBasicNfcFamily from '@taptrack/tappy-basicnfcfamily'
import { SerialCommunicator } from './TappySerialCommunicator'
import Ndef from '@taptrack/ndef'
import { TappyWriteUrlWithPasswordMessage } from './TappyEncodeUrlWithPasswordMessage'

const create = (onConnectCallback) => {
  return new Tappy({
    communicator: SerialCommunicator.create(onConnectCallback),
    errorListener: (message) => {
      console.log(`error:`, message)
    },
    messageListener: (message) => {
      console.log(`default data:`, message)
    },
  })
}

let localTappy = null

const responseResolver = (whenDone) => {
  const resolver = new TappyBasicNfcFamily.Resolver()

  localTappy.setMessageListener((response) => {
    if (resolver.checkFamily(response)) {
      const resolved = resolver.resolveResponse(response)
      if (resolved !== null && resolved.errorCode === undefined) {
        const message = Ndef.Message.fromBytes(resolved.message)
        const records = message.getRecords()
        const url = Ndef.Utils.resolveUriRecordToString(records[0])

        whenDone({ status: 'success', data: url })
        return
      }
      whenDone({ status: 'error', data: resolved.message })
      return
    }

    whenDone({ status: 'error', data: 'Could not parse NFC' })
  })
}

const writeResolver = (whenDone) => {
  const applicationError = 0x7f
  const writeSuccess = 0x05

  localTappy.setMessageListener((response) => {
    const commandCode = response.getCommandCode()
    const payload = response.getPayload().slice(3)

    if (commandCode === applicationError) {
      whenDone({
        status: 'error',
        data: payload.length > 0 ? String.fromCharCode.apply(null, payload) : '',
      })
      return
    }

    if (commandCode === writeSuccess) {
      whenDone({ status: 'success' })
      return
    }

    whenDone({ status: 'error', data: 'Could not parse NFC' })
  })
}

const connect = (onConnectCallback) => {
  localTappy = create(onConnectCallback)

  if (isTappyAvailable()) {
    localTappy.connect(onConnectCallback)
  }
}

const disconnect = () => {
  const disconnectCallback = () => {
    localTappy = null
  }

  if (isTappyAvailable()) {
    localTappy.disconnect(disconnectCallback)
  }
}

const encodeUrl = (url, isLocked = false, onEncode) => {
  if (isTappyAvailable()) {
    writeResolver(onEncode)

    const msg = new Ndef.Message([Ndef.Utils.createUriRecord(url)])
    localTappy.sendMessage(new TappyBasicNfcFamily.Commands.WriteNdefCustom(0, isLocked, msg.toByteArray()))
  }
}

const encodeUrlWithPassword = (url, password, onEncode) => {
  if (isTappyAvailable()) {
    writeResolver(onEncode)

    const urlMessage = new Ndef.Message([Ndef.Utils.createUriRecord(url)])
    localTappy.sendMessage(new TappyWriteUrlWithPasswordMessage(urlMessage, password))
  }
}

const stop = () => {
  if (isTappyAvailable()) {
    localTappy.sendMessage(new TappyBasicNfcFamily.Commands.Stop())
  }
}

const read = (onRead) => {
  if (isTappyAvailable()) {
    responseResolver(onRead)

    localTappy.sendMessage(
      new TappyBasicNfcFamily.Commands.ScanNdef(0, TappyBasicNfcFamily.PollingModes.GENERAL)
    )
  }
}

const isTappyAvailable = () => localTappy !== null

export const TappyWrapper = {
  create,
  connect,
  disconnect,
  encodeUrl,
  encodeUrlWithPassword,
  stop,
  read,
  isTappyAvailable,
}
