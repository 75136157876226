import React from 'react';
import {EuiCard, EuiCallOut} from '@elastic/eui'
import { color } from '../pages/EncodeApp';

type EncodingProcessProps = {
 url: string,
 encodingStatus: string,
 onClick: () => void,
  color: color
}

export const EncodingProcess = ({ url, encodingStatus, onClick, color }: EncodingProcessProps) => (
  <div >
       <EuiCard
        layout="horizontal"
        title={url}
        description={<>
          <EuiCallOut title={encodingStatus} color={color} />
        </>}

        onClick={onClick}
      />
  </div>
);

EncodingProcess.propTypes = {};

