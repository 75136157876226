import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { TappyWrapper } from '../utils'

const useSelectEncoder = () => {
  const { dispatch } = useEncoderContext()

  const selectEncoder = useCallback(() => {
    dispatch({ tag: ActionTags.SearchingDevices })

    TappyWrapper.connect(() => dispatch({ tag: ActionTags.DoneSearching }))
  }, [dispatch])

  return selectEncoder
}

export { useSelectEncoder }
