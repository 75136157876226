type TappyMessage = {
  getCommandFamily: () => Uint8Array,
  getCommandCode: () => number,
  getPayload: () => Uint8Array,
}

const timeout = 0x00
const writeProtection = 0x00
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const readWriteProtection = 0x01

const contentToBytes = (content: string | number): Uint8Array => {
  var escstr = encodeURIComponent(content);
  var binstr = escstr.replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(p1);
  });
  var ua = new Uint8Array(binstr.length);
  Array.prototype.forEach.call(binstr, function (ch, i) {
    ua[i] = ch.charCodeAt(0);
  });
  return ua;
}

const getBytes = (value: number): Buffer => {
  const bytes = new Buffer(8)
  bytes[0] = value
  bytes[1] = value >> 8
  bytes[2] = value >> 16
  bytes[3] = value >> 24
  return bytes
}

export class TappyWriteUrlWithPasswordMessage implements TappyMessage {
  readonly urlMessage: any
  readonly password: string

  constructor(url: unknown, password: string) {
    this.urlMessage = url
    this.password = password
  }

  getCommandFamily = () => new Uint8Array([0x00, 0x06])

  getCommandCode = () => 0x03

  getPayload = () => {
    const parameters: number[] = []

    parameters.push(timeout)
    parameters.push(writeProtection)

    const urlLength = this.urlMessage.toByteArray().length
    const passwordLength = this.password.length

    const urlLengthBytes = getBytes(urlLength)
    const passwordLengthBytes = getBytes(passwordLength)

    parameters.push(passwordLengthBytes[1] ?? 0)
    parameters.push(passwordLengthBytes[0] ?? 0)
    contentToBytes(this.password).forEach(value => parameters.push(value ?? 0))

    parameters.push(urlLengthBytes[1] ?? 0)
    parameters.push(urlLengthBytes[0] ?? 0)

    return new Uint8Array([...parameters, ...this.urlMessage.toByteArray()])
  }
}
