import { Message } from "./state"

enum ActionTags {
  SetList = 'SetList',
  MoveForward = 'MoveForward',
  MoveBackwards = 'MoveBackwards',
}

type MoveForward = {
  tag: ActionTags.MoveForward
}
type MoveBackwards = {
  tag: ActionTags.MoveBackwards
}

type SetList = {
  tag: ActionTags.SetList
  list: Message[]
}

type QueueActions = SetList | MoveForward | MoveBackwards

export type { QueueActions }
export { ActionTags }
