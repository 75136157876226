

type State = {
  list: Message[]
  currentPosition: number
}

type Message = string

const initialState: State = {
  list: [],
  currentPosition: 0,
}

export { initialState }
export type { State, Message }
