
import { useCallback } from 'react'
import { useEncoderContext } from '../store/EncoderContext'
import { ActionTags } from '../store/EncoderStore'
import { ScanNFCResult, TappyWrapper } from '../utils'

export type EncodeUrlWithPassword = {
  password: string | null,
  url: string,
}

const isEncodeWithPassword = (password: string | null) => {
  return password !== null && password.length >= 4;
}

const useEncodeUrlWithPassword = () => {
  const { dispatch } = useEncoderContext()

  const encodeUrl = useCallback(({ password, url }: EncodeUrlWithPassword): Promise<void> => {
    return new Promise((resolve, reject) => {

      dispatch({ tag: ActionTags.EncodingOrScanningStarted })

      if (isEncodeWithPassword(password)) {
        TappyWrapper.encodeUrlWithPassword(url, password, (result: ScanNFCResult) => {
          dispatch({ tag: ActionTags.Idle });

          if (result.status === 'error') {
            reject(result.data)
            return
          }

          resolve()
        })
      }
      else {
        TappyWrapper.encodeUrl(url, false, (result: ScanNFCResult) => {
          dispatch({ tag: ActionTags.Idle });

          if (result.status === 'error') {
            reject(result.data)
            return
          }

          resolve()
        })
      }

    })


  }, [dispatch])

  return encodeUrl
}

export { useEncodeUrlWithPassword }
