import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldText, EuiButton } from '@elastic/eui'
import React, { useState } from 'react'
import { Page } from '../common'
import { useEncoder } from '../encoder/hooks/useEncoder'

const Encoder = (): React.ReactElement => {
  const [values, setValues] = useState({
    url: '',
    password: 'OurUn1queIPisAWES0M3',
  })
  const [readValue, setReadValue] = useState<string | null>(null)

  const { isDevicePaired, isDeviceIdle, encodeUrl, stop, readNFC } = useEncoder()

  const onChange = (key: string, targetValue: string) => (e: any) => {
    setValues({ ...values, [key]: e.target[targetValue] })
  }

  const onSubmit = () => {
    encodeUrl({ lock: false, url: values.url })
  }

  const onRead = async () => {
    const result = await readNFC()
    setReadValue(result)
  }

  return (
    <Page title="Encoder un jeton">
      <EuiFlexGroup style={{ maxWidth: 1200 }}>
        <EuiFlexItem>
          <EuiFormRow label="Url to encode" helpText="Ex: https://redirect.msj.world/nfc/vero/1 ou https://domaine.personnalisé/nfc/1">
            <EuiFieldText disabled={!isDeviceIdle} value={values.url} id="url" onChange={onChange('url', 'value')} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow hasEmptyLabelSpace>
            <>
              <EuiButton disabled={!isDeviceIdle} onClick={onSubmit}>Encode</EuiButton>
              <EuiButton disabled={!isDeviceIdle} onClick={onRead}>Read token</EuiButton>
              {isDevicePaired && !isDeviceIdle && <EuiButton onClick={stop}>Stop</EuiButton>}
              {readValue !== null && readValue}
            </>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  )
}

export { Encoder }
